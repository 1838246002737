import React, { useEffect, useState } from "react"

import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledTooltip
} from "reactstrap"

import classnames from "classnames"
import { Link, useNavigate } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { createDomainProject, getCheckCountByUser, getDomainUrls, getUserPackageDetails } from "services/main.service"
import Loading from "components/Common/Loading"
import toastr from "toastr";

const NewDomainMonitorProject = () => {

    //meta title
    document.title = "Domain Monitoring | Indexed Checker App";
    const navigate = useNavigate();

    const [activeTab, setactiveTab] = useState(1)
    const [activeTabVartical, setoggleTabVertical] = useState(1)

    const [passedSteps, setPassedSteps] = useState([1])
    const [passedStepsVertical, setPassedStepsVertical] = useState([1])
    const [creditCount, setCreditCount] = useState();
    const [userPackageDets, setUserPackageDets] = useState();

    const getCreditCounts = () => {
        getCheckCountByUser().then((project) => {
            console.log(project?.data, "count");
            setCreditCount(project?.data?.count)
        }).catch((error) => {
            console.log({ error });
        })
        getUserPackageDetails().then((project) => {
            console.log(project?.data, "dets");
            setUserPackageDets(project?.data.user_package)
        }).catch((error) => {
            console.log({ error });
        })
    }

    useEffect(() => {
        getCreditCounts()
    }, [])

    function toggleTab(tab) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab]
            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab)
                setPassedSteps(modifiedSteps)
            }
        }
    }

    function toggleTabVertical(tab) {
        if (activeTabVartical !== tab) {
            var modifiedSteps = [...passedStepsVertical, tab]

            if (tab >= 1 && tab <= 4) {
                setoggleTabVertical(tab)
                setPassedStepsVertical(modifiedSteps)
            }
        }
    }

    const [toggleMonitorSitemap, setToggleMonitorSitemap] = useState(false);
    const [toggleCheckDIndex, setToggleCheckDIndex] = useState(false);
    const [toggleSendMails, setToggleSendMails] = useState(false);
    const [toggleStopCheckIndexed, setToggleStopCheckIndexed] = useState(false);
    const [toggleMonitorAll, setToggleMonitorAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [domainData, setDomainData] = useState({
        domain: "",
        monitor_sitemap: toggleMonitorSitemap,
        sitemap_recheck: "Daily",
        project_schedule: "No",
        send_mails: toggleSendMails,
        check_dindex: toggleCheckDIndex,
        stop_check_indexed: toggleStopCheckIndexed,
        monitor_url: toggleMonitorAll
    });

    const [urlsData, setUrlsData] = useState({
        all_urls: "",
        not_checking_urls: "",
    });

    const [scrapeUrls, setScrapeUrls] = useState()
    const [scrapeUrlsNew, setScrapeUrlsNew] = useState()
    const [urlCount, setUrlCount] = useState(0)
    const handleDomainScrape = () => {
        setIsLoading(true)

        console.log(domainData, 'domainData');

        getDomainUrls(domainData).then((res) => {
            console.log(res, 'promptRes');
            setScrapeUrls(res?.data?.data)
            setScrapeUrlsNew(res?.data?.data)
            setUrlsData({ ...urlsData, all_urls: res?.data?.data })
            setUrlCount(res?.data?.count)
            setIsLoading(false)
            setoggleTabVertical(2)
        }).catch((error) => {
            console.log(error);
        });
    }

    const [checkingUrl, setCheckingUrl] = useState()
    const [estimatedCounts, setEstimatedCounts] = useState(0);

    const handleManageUrls = () => {
        console.log(urlsData, 'urlsData');
        if (urlsData && urlsData.all_urls) {
            if (urlsData.not_checking_urls == '') {
                setCheckingUrl(urlsData.all_urls)
                setEstimatedCounts(urlsData.all_urls.split("\n").filter(line => line.trim() !== '').length);
            } else {
                let all_url_array = urlsData.all_urls.split("\n");
                let remove_url_array = urlsData.not_checking_urls.split("\n");

                let checking_url_array = all_url_array.filter(url => !remove_url_array.includes(url));

                setEstimatedCounts(checking_url_array.filter(line => line.trim() !== '').length);
                setCheckingUrl(checking_url_array.join("\n"));

                console.log(checking_url_array, 'checking_url_array');
            }

            setoggleTabVertical(3);
        } else {
            console.error("urlsData or its properties are undefined.");
        }
    }

    const handleSaveProject = () => {
        setIsLoading(true)

        const proj_data = {
            domain: domainData.domain,
            scrape_urls: scrapeUrlsNew,
            all_urls: urlsData.all_urls,
            checking_urls: checkingUrl,
            not_checking_urls: urlsData.not_checking_urls,
            monitor_sitemap: domainData.monitor_sitemap,
            sitemap_recheck: domainData.sitemap_recheck,
            project_schedule: domainData.project_schedule,
            notify_deindex: domainData.check_dindex,
            send_mails: domainData.send_mails,
            stop_check_indexed: domainData.stop_check_indexed,
            monitor_url: domainData.monitor_url,
            pages: estimatedCounts
        }


        console.log(proj_data, 'proj_data');

        createDomainProject(proj_data).then((res) => {
            console.log(res, 'promptRes');
            setIsLoading(false)
            toastr.success("Refresh app for results!")
            navigate('/domain-monitor-list')
        }).catch((error) => {
            console.log(error);
        });
    }
    const [isValidUrl, setIsValidUrl] = useState(true)

    const handleDomainValidate = (e) => {
        setIsValidUrl(false)
        const inputValue = e.target.value;
        setDomainData({ ...domainData, domain: inputValue });

        // Regular expression for URL validation
        const urlRegex = /^(?:(ftp|http|https):\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?\/?/;

        // Check if the input value matches the URL regex pattern
        // const isValidUrl = urlRegex.test(inputValue);
        setIsValidUrl(urlRegex.test(inputValue))

        if (isValidUrl) {
            console.log(isValidUrl, 'Valid URL');
            // You can add further actions if the URL is valid
        } else {
            console.log(isValidUrl, 'Invalid URL');
            // You can add further actions if the URL is invalid
        }
    };

    const handleTextareaChange = (e) => {
        const textareaValue = e.target.value;
        const urls = textareaValue.split('\n').map(line => line.trim()).filter(line => line !== '');

        // Regular expression for URL validation
        const urlRegex = /^(?:(ftp|http|https):\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?\/?/;

        // Filter out non-URL values and remove duplicates
        const uniqueUrls = Array.from(new Set(urls.filter(url => urlRegex.test(url))));

        console.log(uniqueUrls, 'uniqueUrls');
        const domain = domainData.domain;
        const domainUrls = uniqueUrls.filter(url => new RegExp(domain).test(url));

        const limitedUrls = domainUrls.slice(0, userPackageDets?.url_limit_domain);
        console.log(limitedUrls, 'limitedUrls');

        // setEstimatedCounts(uniqueUrls.length);
        setUrlsData({ ...urlsData, all_urls: limitedUrls.join('\n') });
        setScrapeUrls(textareaValue)
        // setProjectData({ ...projectData, project_url: uniqueUrls.join('\n') });
    };


    return (
        <React.Fragment>
            <div className="page-content">
                {
                    isLoading ? (
                        <Loading />
                    ) : null
                }
                <Container fluid={true}>
                    {/* <Breadcrumbs title="Domain Monitor" breadcrumbItem="New Domain Monitor Project" /> */}

                    <Row>

                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">New Domain Monitor Project</h4>
                                    <div className="vertical-wizard wizard clearfix vertical">
                                        <div className="steps clearfix">
                                            <ul>
                                                <NavItem
                                                    className={classnames({
                                                        current: activeTabVartical === 1,
                                                    })}
                                                >
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTabVartical === 1,
                                                        })}
                                                        onClick={() => {
                                                            toggleTabVertical(1)
                                                        }}
                                                        disabled={!(passedStepsVertical || []).includes(1)}
                                                    // disabled
                                                    >
                                                        <span className="number">1.</span> Project Setup
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({
                                                        current: activeTabVartical === 2,
                                                    })}
                                                >
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTabVartical === 2,
                                                        })}
                                                        onClick={() => {
                                                            toggleTabVertical(2)
                                                        }}
                                                        disabled={!(passedStepsVertical || []).includes(2)}
                                                    // disabled
                                                    >
                                                        <span className="number">2.</span>{" "}
                                                        <span>Manage URLs</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({
                                                        current: activeTabVartical === 3,
                                                    })}
                                                >
                                                    <NavLink
                                                        className={
                                                            (classnames({
                                                                active: activeTabVartical === 3,
                                                            }),
                                                                "done")
                                                        }
                                                        onClick={() => {
                                                            toggleTabVertical(3)
                                                        }}
                                                        disabled={!(passedStepsVertical || []).includes(3)}
                                                    // disabled
                                                    >
                                                        <span className="number">3.</span> Finish Project
                                                    </NavLink>
                                                </NavItem>
                                                {/* <NavItem
                                                    className={classnames({
                                                        current: activeTabVartical === 4,
                                                    })}
                                                >
                                                    <NavLink
                                                        className={
                                                            (classnames({
                                                                active: activeTabVartical === 4,
                                                            }),
                                                                "done")
                                                        }
                                                        onClick={() => {
                                                            toggleTabVertical(4)
                                                        }}
                                                        disabled={!(passedStepsVertical || []).includes(4)}
                                                    >
                                                        <span className="number">4.</span> Confirm Detail
                                                    </NavLink>
                                                </NavItem> */}
                                            </ul>
                                        </div>
                                        <div className="content clearfix">
                                            <TabContent
                                                activeTab={activeTabVartical}
                                                className="body"
                                            >
                                                <TabPane tabId={1}>
                                                    <Form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            handleDomainScrape(e);
                                                        }}
                                                    >
                                                        <FormGroup className="mb-4" row>
                                                            <Label
                                                                htmlFor="project_name"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Domain
                                                            </Label>
                                                            <Col lg="10">
                                                                <Input
                                                                    id="domain"
                                                                    name="domain"
                                                                    type="text"
                                                                    required
                                                                    className="form-control"
                                                                    placeholder="Enter Domain..."
                                                                    onChange={handleDomainValidate}
                                                                />
                                                                {(!isValidUrl ? <p className="mt-2" style={{ "color": "red" }}>Domain is not valid!</p> : null)}

                                                            </Col>
                                                        </FormGroup>

                                                        <div className="m-3">
                                                            <Row>
                                                                <Col lg="2"></Col>
                                                                <Col lg="10">
                                                                    <FormGroup row>
                                                                        <div className="form-check form-switch form-switch-md">
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="stop_check_indexed"
                                                                            >
                                                                                Monitor Sitemap {" "}
                                                                                <i className="far fa-question-circle" id="monitorsitemaptool" />
                                                                                <UncontrolledTooltip placement="top" target="monitorsitemaptool">
                                                                                    You can monitor your domain sitemap repeatedly at selected time intervals.
                                                                                </UncontrolledTooltip>
                                                                            </label>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="stop_check_indexed"
                                                                                onClick={(e) => { setDomainData({ ...domainData, monitor_sitemap: !toggleMonitorSitemap }), setToggleMonitorSitemap(!toggleMonitorSitemap) }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {(toggleMonitorSitemap ? (
                                                            <FormGroup className="mb-4" row>
                                                                <Label
                                                                    htmlFor="website"
                                                                    className="col-form-label col-lg-2"
                                                                >
                                                                    Sitemap Recheck Period
                                                                </Label>
                                                                <Col lg="10">
                                                                    <select className="form-control" name="schedule" id="schedule"
                                                                        onChange={(e) => { setDomainData({ ...domainData, sitemap_recheck: e?.target?.value }) }}>
                                                                        <option value="Daily">Daily</option>
                                                                        <option value="Weekly">Weekly</option>
                                                                        <option value="Monthly">Monthly</option>
                                                                    </select>
                                                                </Col>
                                                            </FormGroup>
                                                        ) : null)}

                                                        <FormGroup className="mb-4" row>
                                                            <Label
                                                                htmlFor="schedule"
                                                                className="col-form-label col-lg-2"
                                                            >
                                                                Index Recheck Period {" "}
                                                                <i className="far fa-question-circle" id="recheckperiodtool" />
                                                                <UncontrolledTooltip placement="top" target="recheckperiodtool">
                                                                    You can recheck domain's urls repeatedly at selected time intervals.
                                                                </UncontrolledTooltip>
                                                            </Label>
                                                            <Col lg="10">
                                                                <select className="form-control" name="schedule" id="schedule"
                                                                    onChange={(e) => { setDomainData({ ...domainData, project_schedule: e?.target?.value }) }}>
                                                                    <option value="No">No</option>
                                                                    <option value="Daily">Daily</option>
                                                                    <option value="Weekly">Weekly</option>
                                                                    <option value="Monthly">Monthly</option>
                                                                </select>
                                                            </Col>
                                                        </FormGroup>
                                                        <div className="m-3">
                                                            {/* <Row>
                                                                <Col lg="2"></Col>
                                                                <Col lg="10">
                                                                    <FormGroup row>
                                                                        <div className="form-check form-switch form-switch-md">
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="check_dindex"
                                                                            >
                                                                                Be notified after D-Index
                                                                            </label>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="check_dindex"
                                                                                onClick={(e) => { setDomainData({ ...domainData, check_dindex: !toggleCheckDIndex }), setToggleCheckDIndex(!toggleCheckDIndex) }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row> */}
                                                            <Row>
                                                                <Col lg="2"></Col>
                                                                <Col lg="10">
                                                                    <FormGroup row>
                                                                        <div className="form-check form-switch form-switch-md">
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="stop_check_indexed"
                                                                            >
                                                                                Stop rechecking urls for pages that are already indexed {" "}
                                                                                <i className="far fa-question-circle" id="stoprechecktool" />
                                                                                <UncontrolledTooltip placement="top" target="stoprechecktool">
                                                                                    Once a URL is indexed, it will not be checked again.
                                                                                </UncontrolledTooltip>
                                                                            </label>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="stop_check_indexed"
                                                                                onClick={(e) => { setDomainData({ ...domainData, stop_check_indexed: !toggleStopCheckIndexed }), setToggleStopCheckIndexed(!toggleStopCheckIndexed) }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="2"></Col>
                                                                <Col lg="10">
                                                                    <FormGroup row>
                                                                        <div className="form-check form-switch form-switch-md">
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="send_mails"
                                                                            >
                                                                                Be notified via email when these pages are checked {" "}
                                                                                <i className="far fa-question-circle" id="benotifytool" />
                                                                                <UncontrolledTooltip placement="top" target="benotifytool">
                                                                                    You will receive an email each time the pages are checked.
                                                                                </UncontrolledTooltip>
                                                                            </label>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="send_mails"
                                                                                onClick={(e) => { setDomainData({ ...domainData, send_mails: !toggleSendMails }), setToggleSendMails(!toggleSendMails) }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            {/* <Row>
                                                                <Col lg="2"></Col>
                                                                <Col lg="10">
                                                                    <FormGroup row>
                                                                        <div className="form-check form-switch form-switch-md">
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="monitor_url"
                                                                            >
                                                                                Monitor all url of the project
                                                                            </label>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="monitor_url"
                                                                                onClick={(e) => { setDomainData({ ...domainData, monitor_url: !toggleMonitorAll }), setToggleMonitorAll(!toggleMonitorAll) }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row> */}
                                                        </div>

                                                        <div className="flex-shrink-0">
                                                            <Row className="justify-content-end mb-4">
                                                                <Col lg="12">
                                                                    <Button type="submit" disabled={!isValidUrl} style={{ "backgroundColor": "#00264c", "color": "#fff" }}>
                                                                        Next
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Form>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <div>
                                                        <Form
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                handleManageUrls(e);
                                                            }}
                                                        >
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-pancard-input52">
                                                                            All URLs in {domainData.domain} ({urlCount} urls)
                                                                        </Label>

                                                                        <textarea
                                                                            id="all_urls"
                                                                            name="all_urls"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Urls..."
                                                                            onChange={handleTextareaChange}
                                                                            value={scrapeUrls}
                                                                            rows={10}
                                                                            required
                                                                            readOnly={false}
                                                                        ></textarea>
                                                                        <p className="mt-2">You can add additional urls also (url limit: {userPackageDets?.url_limit_domain})</p>
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col lg="6">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-vatno-input62">
                                                                            URLs not want to check
                                                                        </Label>
                                                                        <textarea
                                                                            id="not_checking_urls"
                                                                            name="not_checking_urls"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter URLs not want to check..."
                                                                            onChange={(e) => { setUrlsData({ ...urlsData, not_checking_urls: e?.target?.value }) }}
                                                                            // value={scrapeUrls}
                                                                            rows={10}
                                                                        ></textarea>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <div className="flex-shrink-0">
                                                                <Row className="justify-content-end mb-4">
                                                                    <Col lg="12">
                                                                        <Button type="submit" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>
                                                                            Next
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                    <div>
                                                        <Form
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                handleSaveProject(e);
                                                            }}
                                                        >
                                                            <Row>
                                                                <Col lg="10">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-pancard-input52">
                                                                            Checking URLs in {domainData.domain}
                                                                        </Label>
                                                                        <textarea
                                                                            id="checking_urls"
                                                                            name="checking_urls"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Urls..."
                                                                            // onChange={(e) => { setUrlsData({ ...urlsData, all_urls: e?.target?.value }) }}
                                                                            value={checkingUrl}
                                                                            rows={10}
                                                                            readOnly
                                                                        ></textarea>
                                                                        <p className="mt-2">Estimated counts: {estimatedCounts}</p>
                                                                        {(creditCount < estimatedCounts ?
                                                                            <Alert color="danger" role="alert">
                                                                                {(creditCount == 0 ? "You have No Credits to check this project" : "You're Credits is not enough to check this project")}
                                                                            </Alert>
                                                                            : null
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <div className="flex-shrink-0">
                                                                <Row className="justify-content-end mb-4">
                                                                    <Col lg="12">
                                                                        <Button type="submit" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>
                                                                            Create Project
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={4}>
                                                    <div className="row justify-content-center">
                                                        <Col lg="6">
                                                            <div className="text-center">
                                                                <div className="mb-4">
                                                                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                                                                </div>
                                                                <div>
                                                                    <h5>Confirm Detail</h5>
                                                                    <p className="text-muted">
                                                                        If several languages coalesce, the grammar
                                                                        of the resulting
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                        {/* <div className="actions clearfix">
                                            <ul>
                                                <li
                                                    className={
                                                        activeTabVartical === 1
                                                            ? "previous disabled"
                                                            : "previous"
                                                    }
                                                >
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            toggleTabVertical(activeTabVartical - 1)
                                                        }}
                                                    >
                                                        Previous
                                                    </Link>
                                                </li>
                                                <li
                                                    className={
                                                        activeTabVartical === 4 ? "next disabled" : "next"
                                                    }
                                                >
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            toggleTabVertical(activeTabVartical + 1)
                                                        }}
                                                    >
                                                        Next
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewDomainMonitorProject
