import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Alert, Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Input, Label, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledTooltip } from "reactstrap";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { savePrompt, saveProjectData, saveProject, updatePrompt, updateProject, genContOnlyPrompt, saveContentOnly, publishOnWordpress, getHasToken, saveWpConfigProject, getProjectsByUser, getCheckCountByUser, getUserPackageDetails } from "../../services/main.service";

import Loading from "../../components/Common/Loading";
import classnames from "classnames"
import Swal from "sweetalert2";
import toastr from "toastr";

const CreateNewProject = () => {

    //meta title
    document.title = "Create New Project | Indexed Checker App";

    const [isLoading, setIsLoading] = useState(false);
    const [toggleStopCheckIndexed, setToggleStopCheckIndexed] = useState(false);
    const [toggleSendMails, setToggleSendMails] = useState(false);
    const [toggleStatusChangeMail, setToggleStatusChangeMail] = useState(false);
    const [toggleMonitorAll, setToggleMonitorAll] = useState(false);
    const [estimatedCounts, setEstimatedCounts] = useState(0);

    const [activeTabVartical, setoggleTabVertical] = useState(1)
    const [passedStepsVertical, setPassedStepsVertical] = useState([1])
    const [creditCount, setCreditCount] = useState();
    const [userPackageDets, setUserPackageDets] = useState();

    const getCreditCounts = () => {
        getCheckCountByUser().then((project) => {
            console.log(project?.data, "count");
            setCreditCount(project?.data?.count)
        }).catch((error) => {
            console.log({ error });
        })

        getUserPackageDetails().then((project) => {
            console.log(project?.data, "dets");
            setUserPackageDets(project?.data.user_package)
        }).catch((error) => {
            console.log({ error });
        })
    }

    function toggleTabVertical(tab) {
        if (activeTabVartical !== tab) {
            var modifiedSteps = [...passedStepsVertical, tab]

            if (tab >= 1 && tab <= 4) {
                setoggleTabVertical(tab)
                setPassedStepsVertical(modifiedSteps)
            }
        }
    }

    const navigate = useNavigate();

    const [projectData, setProjectData] = useState({
        project_name: "",
        project_desc: "",
        project_url: "",
        project_schedule: "No",
        stop_check_indexed: toggleStopCheckIndexed,
        send_mails: toggleSendMails,
        send_mails_status_change: toggleStatusChangeMail,
        monitor_url: toggleMonitorAll,
        pages: 0
    });

    useEffect(() => {
        getProjectsByUser().then((projects) => {
            if (projects?.data?.projects) {
                console.log(projects?.data?.projects, "projects");
            }
        }).catch((error) => {
            console.log({ error });
        }).finally(() => {
            setIsLoading(false);
        });
        getCreditCounts()
    }, [])

    const saveProjectData = (e) => {
        console.log({ projectData });
        setProjectData({ ...projectData, pages: estimatedCounts });
        if (projectData) {
            setLoadingStatus("Saving the project");
            setIsLoading(true);
            saveProject(projectData).then((promptRes) => {
                navigate('/projects-list');
            }).catch((error) => {
                console.log(error);
                if (error.response.status === 401) {
                    localStorage.removeItem('user');
                    navigate('/login');
                }
            });

        }
    }

    const handleTextareaChange = (e) => {
        const textareaValue = e.target.value;
        const urls = textareaValue.split('\n').map(line => line.trim()).filter(line => line !== '');

        // Regular expression for URL validation
        const urlRegex = /^(?:(ftp|http|https):\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?\/?/;

        // Filter out non-URL values and remove duplicates
        const uniqueUrls = Array.from(new Set(urls.filter(url => urlRegex.test(url))));

        console.log(uniqueUrls, 'uniqueUrls');

        const limitedUrls = uniqueUrls.slice(0, userPackageDets?.url_limit_project);

        setEstimatedCounts(limitedUrls.length);
        setProjectData({ ...projectData, project_url: limitedUrls.join('\n') });
    };


    const handleProjectSettings = (e) => {
        setoggleTabVertical(2)
    }

    const handleManageUrls = (e) => {
        if (projectData.project_url == "") {
            Swal.fire("Please Enter valid URLs!", "", "error")
        } else {
            setoggleTabVertical(3)
        }

    }

    const handleCreateProject = (e) => {
        console.log(projectData, 'projectData');
        if (projectData.project_name == "") {
            Swal.fire("Please Enter Project Name!", "", "error")
        } else if (projectData.project_url == "") {
            Swal.fire("Please Enter valid URLs!", "", "error")
        } else {
            setProjectData({ ...projectData, pages: estimatedCounts });
            if (projectData) {
                // setLoadingStatus("Saving the project");
                setIsLoading(true);
                saveProject(projectData).then((promptRes) => {
                    toastr.success("Refresh app for results!")
                    navigate('/projects-list');
                }).catch((error) => {
                    console.log(error);
                    if (error.response?.status === 401) {
                        localStorage.removeItem('user');
                        navigate('/login');
                    }
                });

            }
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                {
                    isLoading ? (
                        <Loading />
                    ) : null
                }

                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Projects" breadcrumbItem="Create New" /> */}

                    {/* <Row>
                        <Col lg="10">
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">Create New Project</CardTitle>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            saveProjectData(e);
                                        }}
                                    >
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="project_name"
                                                className="col-form-label col-lg-2"
                                            >
                                                Project Name
                                            </Label>
                                            <Col lg="10">
                                                <Input
                                                    id="projectname"
                                                    name="project_name"
                                                    type="text"
                                                    maxLength={50}
                                                    required
                                                    className="form-control"
                                                    placeholder="Enter Project Name..."
                                                    onChange={(e) => { setProjectData({ ...projectData, project_name: e?.target?.value }) }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="project_name"
                                                className="col-form-label col-lg-2"
                                            >
                                                Description
                                            </Label>
                                            <Col lg="10">
                                                <textarea
                                                    id="description"
                                                    name="description"
                                                    required
                                                    type="text"
                                                    maxLength={250}
                                                    rows={5}
                                                    className="form-control"
                                                    placeholder="Enter Description..."
                                                    onChange={(e) => { setProjectData({ ...projectData, project_desc: e?.target?.value }) }}
                                                ></textarea>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="website"
                                                className="col-form-label col-lg-2"
                                            >
                                                Website URLs
                                            </Label>
                                            <Col lg="10">
                                                <textarea
                                                    id="website_url"
                                                    name="website_url"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Website URLs here..."
                                                    rows={10}
                                                    onChange={handleTextareaChange}
                                                ></textarea>
                                                <p className="mt-2">Estimated counts: {estimatedCounts}</p>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="website"
                                                className="col-form-label col-lg-2"
                                            >
                                                Recheck Period
                                            </Label>
                                            <Col lg="10">
                                                <select className="form-control" name="schedule" id="schedule"
                                                    onChange={(e) => { setProjectData({ ...projectData, project_schedule: e?.target?.value }) }}>
                                                    <option value="No">No</option>
                                                    <option value="Daily">Daily</option>
                                                    <option value="Weekly">Weekly</option>
                                                    <option value="Monthly">Monthly</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <div className="m-3">
                                            <Row>
                                                <Col lg="2"></Col>
                                                <Col lg="10">
                                                    <FormGroup row>
                                                        <div className="form-check form-switch form-switch-md">
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="stop_check_indexed"
                                                            >
                                                                Stop rechecking urls for pages that are already indexed
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="stop_check_indexed"
                                                                onClick={(e) => { setProjectData({ ...projectData, stop_check_indexed: !toggleStopCheckIndexed }), setToggleStopCheckIndexed(!toggleStopCheckIndexed) }}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="2"></Col>
                                                <Col lg="10">
                                                    <FormGroup row>
                                                        <div className="form-check form-switch form-switch-md">
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="send_mails"
                                                            >
                                                                Be notified via email when these pages are checked
                                                            </label>
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="send_mails"
                                                                onClick={(e) => { setProjectData({ ...projectData, send_mails: !toggleSendMails }), setToggleSendMails(!toggleSendMails) }}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>

                                        <Row className="justify-content-end mb-4">
                                            <Col lg="12">
                                                <Button type="submit" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>
                                                    Create Project
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}

                    <Row>

                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Create New Project</h4>
                                    <div className="vertical-wizard wizard clearfix vertical">
                                        <div className="steps clearfix">
                                            <ul>
                                                <NavItem
                                                    className={classnames({
                                                        current: activeTabVartical === 1,
                                                    })}
                                                >
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTabVartical === 1,
                                                        })}
                                                        onClick={() => {
                                                            toggleTabVertical(1)
                                                        }}
                                                        disabled={!(passedStepsVertical || []).includes(1)}
                                                    >
                                                        <span className="number">1.</span> Project Settings
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({
                                                        current: activeTabVartical === 2,
                                                    })}
                                                >
                                                    <NavLink
                                                        className={classnames({
                                                            active: activeTabVartical === 2,
                                                        })}
                                                        onClick={() => {
                                                            toggleTabVertical(2)
                                                        }}
                                                        disabled={!(passedStepsVertical || []).includes(2)}
                                                    >
                                                        <span className="number">2.</span>{" "}
                                                        <span>Manage URLs</span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem
                                                    className={classnames({
                                                        current: activeTabVartical === 3,
                                                    })}
                                                >
                                                    <NavLink
                                                        className={
                                                            (classnames({
                                                                active: activeTabVartical === 3,
                                                            }),
                                                                "done")
                                                        }
                                                        onClick={() => {
                                                            toggleTabVertical(3)
                                                        }}
                                                        disabled={!(passedStepsVertical || []).includes(3)}
                                                    >
                                                        <span className="number">3.</span> Finish Project
                                                    </NavLink>
                                                </NavItem>
                                                {/* <NavItem
                                                    className={classnames({
                                                        current: activeTabVartical === 4,
                                                    })}
                                                >
                                                    <NavLink
                                                        className={
                                                            (classnames({
                                                                active: activeTabVartical === 4,
                                                            }),
                                                                "done")
                                                        }
                                                        onClick={() => {
                                                            toggleTabVertical(4)
                                                        }}
                                                        disabled={!(passedStepsVertical || []).includes(4)}
                                                    >
                                                        <span className="number">4.</span> Confirm Detail
                                                    </NavLink>
                                                </NavItem> */}
                                            </ul>
                                        </div>
                                        <div className="content clearfix">
                                            <TabContent
                                                activeTab={activeTabVartical}
                                                className="body"
                                            >
                                                <TabPane tabId={1}>
                                                    <Form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            handleProjectSettings(e);
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="basicpill-firstname-input12">
                                                                        Project name
                                                                    </Label>
                                                                    <Input
                                                                        id="projectname"
                                                                        name="project_name"
                                                                        type="text"
                                                                        maxLength={50}
                                                                        required
                                                                        className="form-control"
                                                                        placeholder="Enter Project Name..."
                                                                        onChange={(e) => { setProjectData({ ...projectData, project_name: e?.target?.value }) }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="12">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="basicpill-lastname-input22">
                                                                        Description
                                                                    </Label>
                                                                    <textarea
                                                                        id="description"
                                                                        name="description"
                                                                        required
                                                                        type="text"
                                                                        maxLength={250}
                                                                        rows={5}
                                                                        className="form-control"
                                                                        placeholder="Enter Description..."
                                                                        onChange={(e) => { setProjectData({ ...projectData, project_desc: e?.target?.value }) }}
                                                                    ></textarea>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup className="mb-3">
                                                                    <Label htmlFor="basicpill-phoneno-input32">
                                                                        Recheck Period {" "}
                                                                        <i className="far fa-question-circle" id="recheckperiodtool" />
                                                                        <UncontrolledTooltip placement="top" target="recheckperiodtool">
                                                                            You can recheck your project's urls repeatedly at selected time intervals.
                                                                        </UncontrolledTooltip>
                                                                    </Label>
                                                                    <select className="form-control" name="schedule" id="schedule"
                                                                        onChange={(e) => { setProjectData({ ...projectData, project_schedule: e?.target?.value }) }}>
                                                                        <option value="No">No</option>
                                                                        <option value="Daily">Daily</option>
                                                                        <option value="Weekly">Weekly</option>
                                                                        <option value="Monthly">Monthly</option>
                                                                    </select>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="1"></Col>
                                                            <Col lg="11">
                                                                <FormGroup row>
                                                                    <div className="form-check form-switch form-switch-md">
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="stop_check_indexed"
                                                                        >
                                                                            Stop rechecking urls for pages that are already indexed {" "}
                                                                            <i className="far fa-question-circle" id="stoprechecktool" />
                                                                            <UncontrolledTooltip placement="top" target="stoprechecktool">
                                                                                Once a URL is indexed, it will not be checked again.
                                                                            </UncontrolledTooltip>
                                                                        </label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="stop_check_indexed"
                                                                            onClick={(e) => { setProjectData({ ...projectData, stop_check_indexed: !toggleStopCheckIndexed }), setToggleStopCheckIndexed(!toggleStopCheckIndexed) }}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="1"></Col>
                                                            <Col lg="11">
                                                                <FormGroup row>
                                                                    <div className="form-check form-switch form-switch-md">
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="send_mails"
                                                                        >
                                                                            Be notified via email when these pages are checked {" "}
                                                                            <i className="far fa-question-circle" id="benotifytool" />
                                                                            <UncontrolledTooltip placement="top" target="benotifytool">
                                                                                You will receive an email each time the pages are checked.
                                                                            </UncontrolledTooltip>
                                                                        </label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="send_mails"
                                                                            onClick={(e) => { setProjectData({ ...projectData, send_mails: !toggleSendMails }), setToggleSendMails(!toggleSendMails) }}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row>
                                                            <Col lg="1"></Col>
                                                            <Col lg="11">
                                                                <FormGroup row>
                                                                    <div className="form-check form-switch form-switch-md">
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="send_mails_status_change"
                                                                        >
                                                                            Be notified via email after change of index status of any url
                                                                        </label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="send_mails_status_change"
                                                                            onClick={(e) => { setProjectData({ ...projectData, send_mails_status_change: !toggleStatusChangeMail }), setToggleStatusChangeMail(!toggleStatusChangeMail) }}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row> */}
                                                        <Row>
                                                            <Col lg="1"></Col>
                                                            <Col lg="11">
                                                                <FormGroup row>
                                                                    <div className="form-check form-switch form-switch-md">
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="monitor_url"
                                                                        >
                                                                            Monitor all url of the project {" "}
                                                                            <i className="far fa-question-circle" id="monitoralltool" />
                                                                            <UncontrolledTooltip placement="top" target="monitoralltool">
                                                                                Monitor the index status changes of all URLs.
                                                                            </UncontrolledTooltip>
                                                                        </label>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="monitor_url"
                                                                            onClick={(e) => { setProjectData({ ...projectData, monitor_url: !toggleMonitorAll }), setToggleMonitorAll(!toggleMonitorAll) }}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="11"></Col>
                                                            <Col lg="1">
                                                                <div className="flex-shrink-0">
                                                                    <Row className="justify-content-end mb-4">
                                                                        <Col lg="12">
                                                                            <Button type="submit" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>
                                                                                Next
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <div>
                                                        <Form
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                handleManageUrls(e);
                                                            }}>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-pancard-input52">
                                                                            Website URLs
                                                                        </Label>
                                                                        <textarea
                                                                            id="website_url"
                                                                            name="website_url"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder={`Enter or copy and paste URLs (up to ${userPackageDets?.url_limit_project}, one per line)`}
                                                                            rows={15}
                                                                            onChange={handleTextareaChange}
                                                                            required
                                                                        ></textarea>
                                                                        <p className="mt-2">Estimated counts: {estimatedCounts}</p>
                                                                        {/* {(creditCount < estimatedCounts ?
                                                                            <p className="mt-2" style={{ "color": "red" }}>{(creditCount == 0 ? "You have No Credits to check this project" : "You're Credits is not enough to check this project")}</p> : null
                                                                        )} */}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="11"></Col>
                                                                <Col lg="1">
                                                                    <div className="flex-shrink-0">
                                                                        <Row className="justify-content-end mb-4">
                                                                            <Col lg="12">
                                                                                <Button type="submit" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>
                                                                                    Next
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                    <div>
                                                        <Form
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                handleCreateProject(e);
                                                            }}>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-firstname-input12">
                                                                            Project name
                                                                        </Label>
                                                                        <Input
                                                                            id="projectname"
                                                                            name="project_name"
                                                                            type="text"
                                                                            maxLength={50}
                                                                            required
                                                                            className="form-control"
                                                                            placeholder="Enter Project Name..."
                                                                            value={projectData.project_name}
                                                                            readOnly
                                                                        // onChange={(e) => { setProjectData({ ...projectData, project_name: e?.target?.value }) }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-lastname-input22">
                                                                            Description
                                                                        </Label>
                                                                        <textarea
                                                                            id="description"
                                                                            name="description"
                                                                            required
                                                                            type="text"
                                                                            maxLength={250}
                                                                            rows={5}
                                                                            className="form-control"
                                                                            placeholder="Enter Description..."
                                                                            value={projectData.project_desc}
                                                                            readOnly
                                                                        // onChange={(e) => { setProjectData({ ...projectData, project_desc: e?.target?.value }) }}
                                                                        ></textarea>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-phoneno-input32">
                                                                            Recheck Period
                                                                        </Label>
                                                                        <select className="form-control" name="schedule" id="schedule"
                                                                            // onChange={(e) => { setProjectData({ ...projectData, project_schedule: e?.target?.value }) }}
                                                                            value={projectData.project_schedule}
                                                                            readOnly
                                                                        >
                                                                            <option value="No">No</option>
                                                                            <option value="Daily">Daily</option>
                                                                            <option value="Weekly">Weekly</option>
                                                                            <option value="Monthly">Monthly</option>
                                                                        </select>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup className="mb-3">
                                                                        <Label htmlFor="basicpill-pancard-input52">
                                                                            Website URLs
                                                                        </Label>
                                                                        <textarea
                                                                            id="website_url"
                                                                            name="website_url"
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Website URLs here..."
                                                                            rows={15}
                                                                            value={projectData.project_url}
                                                                            // onChange={handleTextareaChange}
                                                                            readOnly
                                                                        ></textarea>
                                                                        <p className="mt-2">Estimated counts: {estimatedCounts}</p>
                                                                        {(creditCount < estimatedCounts ?
                                                                            <Alert color="danger" role="alert">
                                                                                {(creditCount == 0 ? "You have No Credits to check this project" : "You're Credits is not enough to check this project")}
                                                                            </Alert>
                                                                            : null
                                                                            // <p className="mt-2" style={{ "color": "#dc3545" }}>{(creditCount == 0 ? "You have No Credits to check this project":"You're Credits is not enough to check this project")}</p> : null
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="1"></Col>
                                                                <Col lg="11">
                                                                    <FormGroup row>
                                                                        <div className="form-check form-switch form-switch-md">
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="stop_check_indexed_preview"
                                                                            >
                                                                                Stop rechecking urls for pages that are already indexed
                                                                            </label>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="stop_check_indexed_preview"
                                                                                checked={projectData.stop_check_indexed}
                                                                                disabled
                                                                            // onClick={(e) => { setProjectData({ ...projectData, stop_check_indexed: !toggleStopCheckIndexed }), setToggleStopCheckIndexed(!toggleStopCheckIndexed) }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="1"></Col>
                                                                <Col lg="11">
                                                                    <FormGroup row>
                                                                        <div className="form-check form-switch form-switch-md">
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="send_mails_preview"
                                                                            >
                                                                                Be notified via email when these pages are checked
                                                                            </label>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="send_mails_preview"
                                                                                checked={projectData.send_mails}
                                                                                disabled
                                                                            // onClick={(e) => { setProjectData({ ...projectData, send_mails: !toggleSendMails }), setToggleSendMails(!toggleSendMails) }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            {/* <Row>
                                                                <Col lg="1"></Col>
                                                                <Col lg="11">
                                                                    <FormGroup row>
                                                                        <div className="form-check form-switch form-switch-md">
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="send_mails_status_change_p"
                                                                            >
                                                                                Be notified via email after change of index status of any url
                                                                            </label>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="send_mails_status_change_p"
                                                                                checked={projectData.send_mails_status_change}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row> */}
                                                            <Row>
                                                                <Col lg="1"></Col>
                                                                <Col lg="11">
                                                                    <FormGroup row>
                                                                        <div className="form-check form-switch form-switch-md">
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="monitor_url_preview"
                                                                            >
                                                                                Monitor all url of the project
                                                                            </label>
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="monitor_url_preview"
                                                                                checked={projectData.monitor_url}
                                                                                disabled
                                                                            // onClick={(e) => { setProjectData({ ...projectData, monitor_url: !toggleMonitorAll }), setToggleMonitorAll(!toggleMonitorAll) }}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="10"></Col>
                                                                <Col lg="2">
                                                                    <div className="flex-shrink-0">
                                                                        <Row className="justify-content-end mb-4">
                                                                            <Col lg="12">
                                                                                <Button type="submit" style={{ "backgroundColor": "#00264c", "color": "#fff" }}>
                                                                                    Create Project
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Form>
                                                    </div>
                                                </TabPane>
                                                {/* <TabPane tabId={4}>
                                                    <div className="row justify-content-center">
                                                        <Col lg="6">
                                                            <div className="text-center">
                                                                <div className="mb-4">
                                                                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                                                                </div>
                                                                <div>
                                                                    <h5>Confirm Detail</h5>
                                                                    <p className="text-muted">
                                                                        If several languages coalesce, the grammar
                                                                        of the resulting
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </TabPane> */}
                                            </TabContent>
                                        </div>
                                        {/* <div className="actions clearfix">
                                            <ul>
                                                <li
                                                    className={
                                                        activeTabVartical === 1
                                                            ? "previous disabled"
                                                            : "previous"
                                                    }
                                                >
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            toggleTabVertical(activeTabVartical - 1)
                                                        }}
                                                    >
                                                        Previous
                                                    </Link>
                                                </li>
                                                <li
                                                    className={
                                                        activeTabVartical === 4 ? "next disabled" : "next"
                                                    }
                                                >
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            toggleTabVertical(activeTabVartical + 1)
                                                        }}
                                                    >
                                                        Next
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div> */}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateNewProject;
