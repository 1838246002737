import axios from 'axios'

// const baseUrl = "http://localhost:8000/api";
const baseUrl = "http://127.0.0.1:8000";

console.log(process.env.REACT_APP_BACKEND_ENDPOINT, 'baseUrl');

export const loginUserService = (userData) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/token`, userData);
}

export const registerUserNew = (userData) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/user/register`, userData);
}

export const activeUser = (userId) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/active/user/${userId}`);
}

export const toggleFavourite = (projId) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/toggle/favourite/${projId}`, { headers: getHeaders() });
}

export const recheckProjectRequest = (project_dets) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/recheck/project`, project_dets, { headers: getHeaders() });
}

export const deleteIndexProject = (projId) => {
    return axios.delete(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/delete/project/${projId}`, { headers: getHeaders() });
}

export const recheckSingleURL = (project_dets) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/recheck/url`, project_dets, { headers: getHeaders() });
}

export const recheckMultipleURLs = (project_dets) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/recheck/multiple/url`, project_dets, { headers: getHeaders() });
}

export const getCreditPackages = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/credit/packages`, { headers: getHeaders() });
}

export const requestPayment = (payment_data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/buy/credit/package`, payment_data, { headers: getHeaders() });
}

export const getCheckCountByUser = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/check_count/user`, { headers: getHeaders() });
}

export const requestPasswordChange = (data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/request/password/change`, data);
}

export const resetPassword = (data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/reset/password`, data);
}

export const verifyToken = (token) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/verify/token/${token}`);
}

export const getAllProjectsByUser = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/user/all/projects`, { headers: getHeaders() });
}

export const getAllNormalProjectsByUser = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/user/all/normal/projects`, { headers: getHeaders() });
}

export const getAllDomainProjectsByUser = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/user/all/domain/projects`, { headers: getHeaders() });
}

export const getProjectDetails = (projectId) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/project/details/${projectId}`, { headers: getHeaders() });
}

export const getProjectStatusHistory = (projectId) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/project/history/${projectId}`, { headers: getHeaders() });
}

export const saveProject = (projectData) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/projects/create`, projectData, { headers: getHeaders() });
}

export const addNewUrls = (project_dets) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/add/new/urls`, project_dets, { headers: getHeaders() });
}

export const editProjectDetails = (proj_data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/edit/project/details`, proj_data, { headers: getHeaders() });
}

export const editDomainProjectDetails = (proj_data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/edit/domain/project/details`, proj_data, { headers: getHeaders() });
}

export const getDomainUrls = (proj_data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/domain/scrape`, proj_data, { headers: getHeaders() });
}

export const createDomainProject = (proj_data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/create/domain/project`, proj_data, { headers: getHeaders() });
}

export const deleteSingleURL = (urlId) => {
    return axios.delete(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/delete/single/url/${urlId}`, { headers: getHeaders() });
}

export const getResultsHistory = (projectId) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/results/history/${projectId}`, { headers: getHeaders() });
}

export const getURLHistory = (urlId) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/url/history/${urlId}`, { headers: getHeaders() });
}

export const getDomainProjectDetails = (projectId) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/domain/details/${projectId}`, { headers: getHeaders() });
}

export const getUserDetails = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/user/details`, { headers: getHeaders() });
}

export const updateUserSettings = (data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/update/user/settings`, data, { headers: getHeaders() });
}

export const changeUserPassword = (data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/change/user/password`, data, { headers: getHeaders() });
}

export const createBacklinkProject = (proj_data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/create/backlink/project`, proj_data, { headers: getHeaders() });
}

export const getBacklinkProjectsByUser = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/backlink/project/by_user`, { headers: getHeaders() });
}

export const deleteBacklinkProject = (projId) => {
    return axios.delete(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/delete/backlink/project/${projId}`, { headers: getHeaders() });
}

export const getBacklinkProjectDetails = (projectId) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/backlink/details/${projectId}`, { headers: getHeaders() });
}

export const getUserPackageDetails = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/user/package/details`, { headers: getHeaders() });
}

export const subscriptionCheckoutSession = (package_data) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/subscription/create-checkout-session`, package_data, { headers: getHeaders() });
}

export const getSubscriptionDetails = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/subscription/details`, { headers: getHeaders() });
}

export const getDeindexedUrls = () => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/user/deindexed/urls`, { headers: getHeaders() });
}

export const getProjectNotIndexedURLs = (projectId) => {
    return axios.get(`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/get/user/notindexed/urls/project/${projectId}`, { headers: getHeaders() });
}





export const getProjectsByUser = () => {
    return axios.get(`${baseUrl}/user/projects`, { headers: getHeaders() });
}

export const buyCreditPackage = (package_dets) => {
    console.log(package_dets, 'package_dets');
    return axios.post(`${baseUrl}/buy/credits`, package_dets, { headers: getHeaders() });
}


//old app
const getHeaders = () => {
    let userData = localStorage.getItem('authUser');
    if (userData) {
       let user = JSON.parse(userData);
       console.log({user});
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user?.access}`
        }
    }
    return {
        'Content-Type': 'application/json'
    }
}

export const updateProject = (projectData) => {
    return axios.put(`${baseUrl}/projects/update/${projectData.proj_id}`, projectData, { headers: getHeaders() });
}

export const savePrompt = (promptData) => {
    return axios.post(`${baseUrl}/content/prompt`, promptData, { headers: getHeaders() });
}

export const getProjectbyId = (projId) => {
    return axios.get(`${baseUrl}/project/byid/${projId}`, { headers: getHeaders() });
}

export const getUserPrompts = () => {
    return axios.get(`${baseUrl}/user/prompt`, { headers: getHeaders() });
}

export const generateContent = (contData) => {
    return axios.post(`${baseUrl}/content/creation`, contData, { headers: getHeaders() });
}

export const publishOnWordpress = (wordpressData) => {
    return axios.post(`${baseUrl}/wp/publish`, wordpressData, { headers: getHeaders() });
}

export const getPublicPrompts = () => {
    return axios.get(`${baseUrl}/public/prompt`, { headers: getHeaders() });
}

export const postPromptData = (promptData) => {
    return axios.post(`${baseUrl}/content/prompt`, promptData, { headers: getHeaders() });
}

export const genContOnlyPrompt = (prompt, model, openaikey, isuseuserkey) => {
    return axios.post(`${baseUrl}/multi/initialcont`, {prompt, model, openaikey, isuseuserkey}, { headers: getHeaders() });
}

export const updatePrompt = (prompt_id, propmt_data) => {
    return axios.put(`${baseUrl}/prompt/update/${prompt_id}`, propmt_data, { headers: getHeaders() });
}

export const saveContentOnly = (proj_id, contentData) => {
    return axios.post(`${baseUrl}/content/savesingle/${proj_id}`, contentData, { headers: getHeaders() });
}

export const analyzeSingleBacklink = (uslData) => {
    return axios.post(`${baseUrl}/backlinkapp/tracker`, uslData, { headers: getHeaders() });
}

export const getContentsByProjects = (projectId) => {
    return axios.get(`${baseUrl}/content/byproject/${projectId}`, { headers: getHeaders() });
}

export const saveBacklinkProject = (bkProject) => {
    return axios.post(`${baseUrl}/backlinkapp/tracker`, uslData, { headers: getHeaders() });
}

export const saveBacklinkMultiProject = (projectData) => {
    return axios.post(`${baseUrl}/backlinkapp/create/project`, projectData, { headers: getHeaders() });
}

export const getBacklinkProjects = () => {
    return axios.get(`${baseUrl}/backlinkapp/projectsall`, { headers: getHeaders() });
}

export const getSingleProjectBackLink = (projId) => {
    return axios.get(`${baseUrl}/backlinkapp/projectsingle/${projId}`, { headers: getHeaders() });
}

export const getPublicKey = () => {
    return axios.get(`${baseUrl}/getpubkey`, { headers: getHeaders() });
}

export const saveSecretKey = (secretKey) => {
    return axios.post(`${baseUrl}/seckey/save`, {open_sec: secretKey}, { headers: getHeaders() });
}

export const getHasToken = () => {
    return axios.get(`${baseUrl}/seckey/haskey`, { headers: getHeaders() });
}

export const deleteBackLinkProject = (projId) => {
    return axios.delete(`${baseUrl}/backlinkapp/delete/${projId}`, { headers: getHeaders() });
}

export const updateBacklinkProjectStatus = (projId) => {
    return axios.get(`${baseUrl}/backlinkapp/status/${projId}`, { headers: getHeaders() });
}

export const saveWpConfigProject = (wpConfigProjData) => {
    return axios.post(`${baseUrl}/project/savewpconfig`, wpConfigProjData, { headers: getHeaders() });
}

export const generateTitlesForContent = (initprompt, isuseuserkey, num_of_points = 10) => {
    return axios.post(`${baseUrl}/contentmulti/initial`, {initprompt, isuseuserkey, num_of_points}, { headers: getHeaders() });
}

export const generateFullContentForTitles = (inputData) => {
    return axios.post(`${baseUrl}/contentmulti/secondstep`, inputData, { headers: getHeaders() });
}

export const getGeneratedFullContentForTitles = () => {
    return axios.get(`${baseUrl}/contentmulti/list`, { headers: getHeaders() });
}

export const getContentsTitlesSingle = (proj_id) => {
    return axios.get(`${baseUrl}/contentmulti/content/${proj_id}`, { headers: getHeaders() });
}