import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, CardTitle, Col, Container, FormGroup, Input, Label, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Table, Modal, FormFeedback, Badge, UncontrolledDropdown, UncontrolledTooltip, InputGroup } from "reactstrap";
import { Form as Form1 } from 'reactstrap';

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";

import { getProjectsByUser, getAllProjectsByUser, getProjectDetails, recheckSingleURL, deleteSingleURL, addNewUrls, recheckProjectRequest, recheckMultipleURLs, getProjectStatusHistory, editProjectDetails, getResultsHistory, getProjectNotIndexedURLs } from "../../services/main.service";

import Loading from "../../components/Common/Loading";

import PropTypes from 'prop-types';
import StatusChart from "../projects/statusChart";

import Swal from "sweetalert2";
import { Formik, Form } from "formik";
import BootstrapTable from "react-bootstrap-table-next";
import { CSVLink } from "react-csv";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ReactApexChart from "react-apexcharts";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import paginationFactory from "react-bootstrap-table2-paginator";

function IndexerAllProjects(props) {

    const { id } = useParams();
    // console.log(id, 'id');

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const [logDataArr, setLogDataArr] = useState([]);
    const [dataArr, setDataArr] = useState([]);

    const [allProjects, setAllProjects] = useState([]);
    const [statusHistoryResults, setStatusHistoryResults] = useState([]);
    const [oldestURLHistory, setOldestURLHistory] = useState([]);
    const [latestURLHistory, setLatestURLHistory] = useState([]);
    const [timePeriod, setTimePeriod] = useState(["2024-03-05", "2024-03-07"]);
    const [projectId, setProjectId] = useState();

    useEffect(() => {
        getAllProjects()
    }, [])

    const getAllProjects = () => {
        getAllProjectsByUser().then((project) => {
            console.log(project?.data, "project_dets");
            setAllProjects(project?.data?.projects)
            getNotIndexedURLs(project?.data?.projects[0].id)
            setProjectId(project?.data?.projects[0].id)
        }).catch((error) => {
            console.log({ error });
            if (error.response.status === 401) {
                localStorage.removeItem('user');
                navigate('/login');
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const handleSelectProject = (val) => {
        console.log(val, 'val');
        if (val) {
            getNotIndexedURLs(val);
            setProjectId(val);
        }
    }

    const getNotIndexedURLs = (proj_id) => {

        getProjectNotIndexedURLs(proj_id).then((project) => {
            console.log(project.data.results, 'detatisl');
            setDataArr(project.data.results)
        });
    }

    const headerStyle = { textAlign: 'center' };

    const rowStyle = (row, rowIndex) => {
        const style = {};
        if (row.main_status == 'Indexed') {
            style.backgroundColor = '#eff2f7';
        } else if (row.main_status == 're_indexed') {
            style.backgroundColor = '#d6f3e9';
        } else if (row.main_status == 'de_indexed') {
            style.backgroundColor = '#fdcdcd';
        } else {
            style.backgroundColor = '#fcf0db';
        }

        return style;
    };

    const columns = [
        // {
        //     dataField: 'id',
        //     text: '#',
        //     sort: true
        // },
        {
            dataField: 'url',
            text: 'URL',
            sort: true,
            // filter: textFilter()
        }, {
            dataField: 'url_status',
            text: 'URL Status',
            sort: true
        }, {
            dataField: 'status',
            text: 'Status',
            sort: true,
            headerStyle,
            style: {
                textAlign: "center",
            },
            formatter: (cell, row) => (
                (<Badge className="bg-warning">{cell}</Badge>)

            )
        }];

    const [selectedRows, setSelectedRows] = useState([])


    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelectedRows([...selectedRows, row]);
            } else {
                setSelectedRows(selectedRows.filter(selectedRow => selectedRow !== row));
            }
            console.log(row, 'rowId');
            console.log(isSelect, 'isSelect');
            console.log(rowIndex, 'rowIndex');
            console.log(e);
        },
        hideSelectAll: true,
    };

    return (
        <div className="page-content">
            {
                isLoading ? (
                    <Loading />
                ) : null
            }

            <Container fluid>
                {/* Render Breadcrumbs */}
                {/* <Breadcrumbs title="Reports" breadcrumbItem="Project Status" /> */}
                <Row>
                    <Col lg="12" className="mb-2">
                        <Row>
                            <Col lg="4">
                                <div className="d-flex">
                                    <div className="flex-grow-1 align-self-center">
                                        <h5 className="mb-1">Index your URLs</h5>
                                    </div>
                                </div>
                            </Col>

                            <Col lg="6" className="align-self-center">
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col xl={12}>

                        <Row className="mb-2">
                            <Col lg={4}>
                                <FormGroup row>
                                    <label
                                        htmlFor="select_project"
                                        className="col-md-2 col-form-label"
                                    >
                                        Project:
                                    </label>
                                    <div className="col-md-10">
                                        <select className="form-control" name="select_project" id="select_project"
                                            onChange={(e) => handleSelectProject(e.target.value)}
                                            value={projectId}>
                                            {/* <option value="">Select Project</option> */}
                                            {(allProjects.map((project) => (
                                                <option value={project?.id}>{project?.project_name}</option>
                                            )))}

                                        </select>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <CardBody>
                                    <div className="flex-shrink-0 mb-2">
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} disabled onClick={() => recheckProject()}>Index All Pages</button>
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} disabled onClick={() => recheckProject(selectedRows)}>Index({selectedRows.length})</button>
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => navigate('/project-details/' + projectId)}>More Details</button>
                                        <button className="btn me-1" style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => navigate('/report-log/' + projectId)}>History Log</button>
                                        <button style={{ "backgroundColor": "#00264c", "color": "#fff" }} onClick={() => window.location.reload()} className="btn btn-light me-1"><i className="bx bx-slider-alt"></i></button>
                                    </div>
                                </CardBody>
                            </Col>
                        </Row>


                        <Card>
                            <CardBody>
                                {/* <CardTitle className="h4">Hoverable </CardTitle> */}
                                <div>
                                    {dataArr.length == 0 ? (
                                        <center>
                                            <h3 className="mt-2">All URLs are Indexed.</h3>
                                        </center>
                                    ) : (
                                        <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            data={dataArr}
                                            columns={columns}
                                            // rowStyle={rowStyle}
                                            filter={filterFactory()}
                                            pagination={paginationFactory()}
                                            selectRow={selectRow}
                                        />
                                    )}

                                </div>


                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
IndexerAllProjects.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};

export default IndexerAllProjects;
